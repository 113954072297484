import React, { useState, useEffect } from 'react';
import { getControllersApi } from '../api/api'; // Импорт функции API
import LoadingSpinner from '../components/LoadingSpinner'; // Импорт компонента спиннера

const Table = () => {
  const [data, setData] = useState([]); // Состояние для данных контроллеров
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [error, setError] = useState(null); // Состояние для ошибок

  // Функция загрузки данных
  const fetchControllers = () => {
    setIsLoading(true);
    setError(null);

    getControllersApi(
      (controllersData) => {
        setData(controllersData || []); // Устанавливаем контроллеры
        setIsLoading(false);
      },
      (err) => {
        setError(err); // Устанавливаем ошибку
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchControllers(); // Загружаем данные при монтировании
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen p-4 sm:p-6">
      {isLoading ? (
        // Центрируем спиннер по экрану
        <div className="fixed inset-0 flex justify-center items-center bg-gray-100">
          <LoadingSpinner loading={true} />
        </div>
      ) : (
        <div className="max-w-7xl mx-auto bg-white shadow-md rounded-3xl p-4">
          <h2 className="text-xl font-semibold mb-4 text-center">Контроллеры</h2>

          {error ? (
            <p className="text-red-500 text-center">Ошибка: {error.error || 'Не удалось загрузить данные'}</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full border-collapse text-sm sm:text-base">
                <thead>
                  <tr className="text-left text-gray-500 text-xs sm:text-sm uppercase border-b border-gray-200">
                    <th className="py-2 px-4">Серийный номер</th>
                    <th className="py-2 px-4">ID объекта</th>
                    <th className="py-2 px-4">Описание</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors`}>
                      <td className="py-2 px-4 text-gray-700">{item.serial_id}</td>
                      <td className="py-2 px-4 text-gray-700">{item.object_id !== null ? item.object_id : 'Не назначено'}</td>
                      <td className="py-2 px-4 text-gray-700">{item.description || 'Нет описания'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Table;
