import React, { useState, useEffect } from 'react';
import { getAddressesApi, getControllersApi } from '../api/api'; // Импорт функций API
import LoadingSpinner from '../components/LoadingSpinner'; // Импорт компонента спиннера

const Table = () => {
  const [data, setData] = useState([]); // Состояние для данных адресов
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [error, setError] = useState(null); // Состояние для ошибок

  // Функция загрузки данных
  const fetchAddresses = () => {
    setIsLoading(true); // Включаем загрузку
    setError(null); // Сбрасываем ошибки

    getAddressesApi(
      (addressesData) => {
        setData(addressesData || []); // Устанавливаем адреса
        setIsLoading(false); // Отключаем загрузку
      },
      (err) => {
        setError(err); // Устанавливаем ошибку
        setIsLoading(false); // Отключаем загрузку
      }
    );

    getControllersApi(
      (controllersData) => {
        // Обработайте данные контроллеров, если нужно
      },
      (err) => {
        // Обработайте ошибки для контроллеров, если нужно
      }
    );
  };

  useEffect(() => {
    fetchAddresses(); // Загружаем данные при монтировании
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen p-4 sm:p-6">
      <div className="max-w-7xl mx-auto bg-white shadow-md rounded-3xl p-4">
        <h2 className="text-xl font-semibold mb-4 text-center">Адреса</h2>

        {isLoading ? (
          // Центрируем спиннер по экрану
          <div className="fixed inset-0 flex justify-center items-center bg-gray-100">
            <LoadingSpinner loading={true} />
          </div>
        ) : error ? (
          // Показываем сообщение об ошибке, если есть ошибка
          <p className="text-red-500 text-center">Ошибка: {error.error || 'Не удалось загрузить данные'}</p>
        ) : (
          // Показываем таблицу, если данные загружены
          <div className="overflow-x-auto">
            <table className="w-full border-collapse text-sm sm:text-base">
              <thead>
                <tr className="text-left text-gray-500 text-xs sm:text-sm uppercase border-b border-gray-200">
                  <th className="py-2 px-4">Адрес</th>
                  <th className="py-2 px-4">Количество контроллеров</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors`}>
                    <td className="py-2 px-4 text-gray-700">{item.address}</td>
                    <td className="py-2 px-4 text-gray-700">{item.controllers_count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
