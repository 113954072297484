import React, { useEffect } from 'react';
import { getUserApi } from '../api/api';
import Input from '../components/Input';
import InputPasswd from '../components/InputPasswd';
import LoadingSpinner from '../components/LoadingSpinner';

const Settings = () => {
  const [_user, _setUser] = React.useState({
    username: '',
    phone: '',
    email: '',
    inn: '',
    description: '',
    password: '',
  });

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const dataCb = (data) => {
      // Здесь данные из API присваиваем в состояние
      _setUser({
        username: data.username || '',
        phone: data.phone || '',
        email: data.email || '',
        inn: data.inn || '',
        description: data.description || '',
        password: '', // Пароль не извлекаем из API
      });
      setLoading(false); // Заканчиваем загрузку после получения данных
    };

    const errCb = (error) => {
      // Обработка ошибок, если данные не удалось получить
      console.log('Error fetching user data:', error);
      setLoading(false); // Заканчиваем загрузку, даже если произошла ошибка
    };

    // Вызываем API для получения данных пользователя
    getUserApi(dataCb, errCb);
  }, []);

  return (
    <div className="sm:flex min-h-screen items-start justify-center bg-gradient-to-r p-2 sm:p-4 from-[#ededed] to-[#dcdcdc]">
      {!loading ? (
        <div className="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
          <div className="flex items-center space-x-4 my-4">
            <p className="truncate text-3xl font-normal">Настройки</p>
          </div>
          <div className="bg-white shadow-3xl rounded-3xl p-4">
            <div className="w-full mb-4">
              <Input
                label="Логин"
                value={_user.username}
                inputCb={(e) => _setUser({ ..._user, username: e.target.value })}
                id="username"
                type="text"
                autocomplete="username"
                placeholder="meridian"
              />
            </div>
            <div className="w-full mb-4">
              <Input
                label="Телефон"
                value={_user.phone}
                inputCb={(e) => _setUser({ ..._user, phone: e.target.value })}
                id="phone"
                type="tel"
                autocomplete="tel"
                placeholder="79311111111"
              />
            </div>
            <div className="w-full mb-4">
              <Input
                label="Электронная почта"
                value={_user.email}
                inputCb={(e) => _setUser({ ..._user, email: e.target.value })}
                id="email"
                type="email"
                autocomplete="email"
                placeholder="rise33@yandex.ru"
              />
            </div>
            <div className="w-full mb-4">
              <Input label="ИНН" value={_user.inn} inputCb={(e) => _setUser({ ..._user, inn: e.target.value })} id="inn" type="text" placeholder="12345678" />
            </div>
            <div className="w-full mb-4">
              <Input
                label="Описание"
                value={_user.description}
                inputCb={(e) => _setUser({ ..._user, description: e.target.value })}
                id="description"
                type="text"
                placeholder="description"
              />
            </div>
            <div className="w-full mb-4">
              <InputPasswd
                label="Пароль"
                value={_user.password}
                inputCb={(e) => _setUser({ ..._user, password: e.target.value })}
                id="password"
                autocomplete="current-password"
              />
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner loading={true} />
      )}
    </div>
  );
};

export default Settings;
