import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getSummaryApi } from '../api/api';
import LoadingSpinner from '../components/LoadingSpinner';

const ObjectsController = () => {
  const [summary, setSummary] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true); // New state for loading spinner

  useEffect(() => {
    getSummaryApi(
      (data) => {
        if (data) {
          console.log('Summary data:', data);
          setSummary(data);
          setIsVisible(true); // Trigger animation visibility
        }
        setLoading(false); // Hide spinner once data is fetched
      },
      (error) => {
        console.error('Error fetching summary:', error);
        setLoading(false); // Hide spinner even if there is an error
      }
    );
  }, []);

  const numberAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      {loading ? (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-100">
          <LoadingSpinner loading={true} />
        </div>
      ) : (
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left section: Data */}
          <div className="col-span-1 lg:col-span-2 space-y-6">
            {/* Summary Data */}
            <div className="bg-white shadow-lg rounded-3xl p-6">
              <h2 className="text-xl font-semibold mb-4">Данные</h2>
              <div className="space-y-3">
                <motion.div
                  initial="hidden"
                  animate={isVisible ? 'visible' : 'hidden'}
                  variants={numberAnimation}
                  className="flex justify-between text-gray-700">
                  <span>Адресов</span>
                  <span className="text-orange-500 font-bold">{summary.address_count}</span>
                </motion.div>
                <motion.div
                  initial="hidden"
                  animate={isVisible ? 'visible' : 'hidden'}
                  variants={numberAnimation}
                  className="flex justify-between text-gray-700">
                  <span>Объектов</span>
                  <span className="text-orange-500 font-bold">{summary.object_count}</span>
                </motion.div>
                <motion.div
                  initial="hidden"
                  animate={isVisible ? 'visible' : 'hidden'}
                  variants={numberAnimation}
                  className="flex justify-between text-gray-700">
                  <span>Пользователей</span>
                  <span className="text-orange-500 font-bold">{summary.user_count}</span>
                </motion.div>
              </div>
            </div>

            {/* Connected Equipment */}
            <div className="bg-white shadow-lg rounded-3xl p-6">
              <h2 className="text-xl font-semibold mb-4">Подключенное оборудование</h2>
              <div className="space-y-3">
                <motion.div
                  initial="hidden"
                  animate={isVisible ? 'visible' : 'hidden'}
                  variants={numberAnimation}
                  className="flex justify-between text-gray-700">
                  <span>Счетчиков ХВ</span>
                  <span className="text-orange-500 font-bold">{summary.cold_water_count}</span>
                </motion.div>
                <motion.div
                  initial="hidden"
                  animate={isVisible ? 'visible' : 'hidden'}
                  variants={numberAnimation}
                  className="flex justify-between text-gray-700">
                  <span>Счетчиков ГВ</span>
                  <span className="text-orange-500 font-bold">{summary.hot_water_count}</span>
                </motion.div>
                <motion.div
                  initial="hidden"
                  animate={isVisible ? 'visible' : 'hidden'}
                  variants={numberAnimation}
                  className="flex justify-between text-gray-700">
                  <span>Датчиков протечки</span>
                  <span className="text-orange-500 font-bold">{summary.leakage_sensor_count}</span>
                </motion.div>
                <motion.div
                  initial="hidden"
                  animate={isVisible ? 'visible' : 'hidden'}
                  variants={numberAnimation}
                  className="flex justify-between text-gray-700">
                  <span>Датчиков температуры</span>
                  <span className="text-orange-500 font-bold">{summary.temperature_count}</span>
                </motion.div>
                <motion.div
                  initial="hidden"
                  animate={isVisible ? 'visible' : 'hidden'}
                  variants={numberAnimation}
                  className="flex justify-between text-gray-700">
                  <span>Всего</span>
                  <span className="text-orange-500 font-bold">{summary.total}</span>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ObjectsController;
