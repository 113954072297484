import React, { useState, useEffect } from 'react';
import { getUsersApi } from '../api/api'; // API function
import LoadingSpinner from '../components/LoadingSpinner'; // Import the spinner component

const Table = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [count] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUsers = (initial = false) => {
    if (initial) setIsInitialLoading(true);
    else setIsLoading(true);

    setError(null);

    getUsersApi(
      page,
      count,
      (usersData) => {
        setData(usersData.items || []);
        setTotalUsers(usersData.totalUsers || 0);
        setIsLoading(false);
        setIsInitialLoading(false);
      },
      (err) => {
        setError(err);
        setIsLoading(false);
        setIsInitialLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchUsers(true);
  }, []);

  useEffect(() => {
    if (!isInitialLoading) {
      fetchUsers();
    }
  }, [page]);

  const totalPages = Math.ceil(totalUsers / count - 1);

  const handlePageClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setPage(pageNumber);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-4 sm:p-6">
      <div className="max-w-7xl mx-auto bg-white shadow-md rounded-3xl p-4">
        <h2 className="text-xl font-semibold mb-4 text-center">Пользователи</h2>

        {error ? (
          <p className="text-red-500 text-center">Ошибка: {error.error || 'Не удалось загрузить данные'}</p>
        ) : isInitialLoading ? (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-100">
            <LoadingSpinner loading={true} />
          </div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse text-sm sm:text-base">
                <thead>
                  <tr className="text-left text-gray-500 text-xs sm:text-sm uppercase border-b border-gray-200">
                    <th className="py-2 px-4">ФИО</th>
                    <th className="py-2 px-4">Email</th>
                    <th className="py-2 px-4">Телефон</th>
                    <th className="py-2 px-4">Регистрация</th>
                    <th className="py-2 px-4">Авторизация</th>
                    <th className="py-2 px-4">Объекты</th>
                    <th className="py-2 px-4">Действия</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors`}>
                      <td className="py-2 px-4 text-gray-700">{item.fullname}</td>
                      <td className="py-2 px-4 text-gray-700">{item.email}</td>
                      <td className="py-2 px-4 text-gray-700">{item.phone}</td>
                      <td className="py-2 px-4 text-gray-700">{new Date(item.created_at).toLocaleString()}</td>
                      <td className="py-2 px-4 text-gray-700">{new Date(item.last_login_at).toLocaleString()}</td>
                      <td className="py-2 px-4 text-gray-700">{item.object_count}</td>
                      <td className="py-2 px-4 text-center">
                        <button className="w-8 h-8 rounded-full border border-orange-500 text-orange-500 hover:bg-orange-500 hover:text-white transition">
                          ✓
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-4">
              <div className="overflow-x-auto whitespace-nowrap space-x-2">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageClick(pageNumber)}
                    className={`px-4 py-2 rounded-full text-sm ${page === pageNumber ? 'bg-orange-500 text-white' : 'text-gray-700 hover:bg-gray-300'}`}>
                    {pageNumber}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Table;
